<template>
  <v-app-bar app clipped-left clipped-right color="blue-grey" dark>
    <v-toolbar-title class="align-center d-flex">
      <span class="logo-icon">
        <img src="../../assets/logo.png" class="mt-2" height="50vh" />
      </span>
      <span class="logo-text black--text ml-2 font-weight-bold">
        <h3>ANTEC</h3>
      </span>
    </v-toolbar-title>
    <v-app-bar-nav-icon
      class="d-block d-md-none"
      @click="
        $vuetify.breakpoint.smAndDown
          ? setSidebarDrawer(!Sidebar_drawer)
          : $emit('input', !value)
      "
    />
    <v-spacer />
    <!---right part -->

    <v-menu bottom left transition="scale-transition">
      <template v-slot:activator="{ on }">
        <v-btn dark class="py-2" color="primary" depressed v-on="on" v-if="userData != null">
          <v-avatar color="light-grey" size="30"
            >{{ userData.firstName[0] }}{{ userData.lastName[0] }}</v-avatar
          >
          <span class="white--text ml-1"
            >{{ userData.firstName }} {{ userData.lastName }}</span
          >
        </v-btn>
        <v-btn dark color="primary" depressed v-on="on" v-else>
          <span class="white--text ml-1">Exit</span>
        </v-btn>
      </template>

      <v-list class="pt-1 pb-1" dense>
        <v-list-item
          @click="goToProfile"
          class="gray--text text--darken-3"
          v-if="userData != null"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Profil</v-list-item-title>
        </v-list-item>
        <v-list-item @click="userLogOut" class="gray--text text--darken-3">
          <v-list-item-icon>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Sistemdən çıx</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      isLoggingOut: false,
    };
  },
  computed: {
    ...mapGetters({
      userData: "getUserData",
    }),
    isActive() {
      return this.$route.path.startsWith("/profile");
    },
  },
  methods: {
    setUser() {
      this.setUserData(JSON.parse(localStorage.getItem("user_data")));
    },

    ...mapMutations({
      setSidebarDrawer: "SET_SIDEBAR_DRAWER",
    }),
    ...mapActions({
      logOut: "logOut",
      setUserData: "setUserData",
    }),
    goToProfile() {
      this.$router.push({ name: "Profile" }).catch(() => {});
    },
    async userLogOut() {
      this.isLoggingOut = true;
      this.logOut();
      this.$router.push("/login");
    },
  },
  mounted() {
    this.setUser();
  },
};
</script>

<style lang="scss" scoped>
.a-user-dropdown {
  &__button {
    position: relative;
    padding: 0 12px 0 42px;
    border-radius: 50px;
    transition: background-color 0.1s ease-in-out;
    outline: none;
    height: 36px;

    &__text {
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--activated {
      background-color: rgba(0, 104, 166, 0.12);

      .a-user-dropdown__button__text {
        color: var(--v-primary-base) !important;
      }
    }

    &:hover:not(&--activated) {
      transition: background-color 0.1s ease-in-out;
      background-color: var(--v-gray-lighten3);
      color: var(--v-dark-base);
    }

    &:active:not(&--activated),
    &:focus:not(&--activated) {
      transition: background-color 0.1s ease-in-out;
      background-color: var(--v-gray-lighten2);
      color: var(--v-dark-base);
    }

    .a-avatar {
      left: 2px;
      top: 2px;
    }
  }
}
</style>
